import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import raw from "discourse/helpers/raw";
export default class NewListHeaderControlsWrapper extends Component {
    @action
    click(e1) {
        const target1 = e1.target;
        if (target1.closest("button.topics-replies-toggle.--all")) {
            this.args.changeNewListSubset(null);
        } else if (target1.closest("button.topics-replies-toggle.--topics")) {
            this.args.changeNewListSubset("topics");
        } else if (target1.closest("button.topics-replies-toggle.--replies")) {
            this.args.changeNewListSubset("replies");
        }
    }
    static{
        template(`
    <div
      {{! template-lint-disable no-invalid-interactive }}
      {{on "click" this.click}}
      class="topic-replies-toggle-wrapper"
    >
      {{raw
        "list/new-list-header-controls"
        current=@current
        newRepliesCount=@newRepliesCount
        newTopicsCount=@newTopicsCount
        noStaticLabel=true
      }}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
